// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  $breakpoint-counter: 0;
  @each $breakpoint in map-keys($breakpoints) {
    $breakpoint-counter: ($breakpoint-counter + 1);
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $enable-flex {
        .col-#{$breakpoint} {
          position: relative;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          min-height: 1px;
          padding-right: ($gutter / 2);
          padding-left:  ($gutter / 2);
        }
      }

      @for $i from 1 through $columns {
        .col-#{$breakpoint}-#{$i} {
          @include make-col($i, $columns, $gutter);
        }
      }

      @each $modifier in (pull, push) {
        @for $i from 0 through $columns {
          .#{$modifier}-#{$breakpoint}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns)
          }
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if $breakpoint-counter != 1 or $i != 0 { // Avoid emitting useless .offset-xs-0
          .offset-#{$breakpoint}-#{$i} {
            @include make-col-modifier(offset, $i, $columns)
          }
        }
      }
    }
  }
}
