// Forms
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-clip: padding-box;
  border: $input-btn-border-width solid $input-border-color;

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-color-placeholder;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }
}

.form-control-search {
  height: 3.5rem;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  border-radius: $input-height-lg;
}

.sr-only {
  @include sr-only();
}
