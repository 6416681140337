// Buttons
//

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: $input-btn-border-width solid transparent;
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
  @include transition(all 0.2s ease-in-out);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus();
    }
  }

  @include hover-focus {
    text-decoration: none;
  }

  &.focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
    outline: 0;
    @include box-shadow($btn-active-box-shadow);
  }
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
