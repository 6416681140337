// Responsive Type
//
html { font-size: 12px; }

@include media-breakpoint-up(sm) {
  html { font-size: 14px; }
}

@include media-breakpoint-up(md) {
  html { font-size: 16px; }
}

@include media-breakpoint-up(lg) {
  html { font-size: 18px; }
}

// Scaffolding
//
body {
  background: #fff;
}

footer {
  background: $gray-lightest;
}

.header-logo {
  width: 4rem;
  margin-right: auto;
  margin-bottom: 0.5rem;
  margin-left: auto;
  @include img-fluid();
}

// Navigation
//
.nav {

  a {
    display: block;
    padding-top: $btn-padding-y;
    padding-bottom: $btn-padding-y;
  }
}

.disclaimer {
  padding: 1rem 1rem 0;
  margin-bottom: 1rem;
  background-color: $gray-lightest;
  border: 1px solid $gray-light;
  border-radius: $border-radius;
}

.dosage {
  padding: 1rem 1rem 0;
  margin-bottom: 1rem;
  border: 4px dashed $brand-danger;
  border-radius: $border-radius;
}

.drug-card {
  padding: 2rem;
  background: #fffae5;
  border: 1px solid #ddd;
}

.drug-title {
  hyphens: auto;
  word-wrap: break-word;
}
